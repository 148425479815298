/*
 Sticky-kit v1.1.4 | MIT | Leaf Corcoran 2015 | http://leafo.net
 [Alexandre Stimpfling] [2019-03-06 11:58] -> JS recup du fork suivant : https://github.com/Stiropor/sticky-kit
*/
(function () {
  var g = window.jQuery;
  var h = g(window);
  var J = g(document);
  g.fn.stick_in_parent = function (b) {
    var w;
    null == b && (b = {});
    var u = b.sticky_class;
    var D = b.inner_scrolling;
    var K = b.recalc_every;
    var x = b.parent;
    var p = b.offset_top;
    var q = b.spacer;
    var y = b.bottoming;
    var E = h.height();
    var F = J.height();
    null == p && (p = 0);
    null == x && (x = void 0);
    null == D && (D = !0);
    null == u && (u = "is_stuck");
    null == y && (y = !0);
    var M = function (a) {
      if (window.getComputedStyle) {
        a = window.getComputedStyle(a[0]);
        var b = parseFloat(a.getPropertyValue("width")) +
          parseFloat(a.getPropertyValue("margin-left")) + parseFloat(a.getPropertyValue("margin-right"));
        "border-box" !== a.getPropertyValue("box-sizing") && (b += parseFloat(a.getPropertyValue("border-left-width")) + parseFloat(a.getPropertyValue("border-right-width")) + parseFloat(a.getPropertyValue("padding-left")) + parseFloat(a.getPropertyValue("padding-right")));
        return b
      }
      return a.outerWidth(!0)
    };
    var N = function (a, b, t, z, G, r, m, H) {
      var k, d;
      if (!a.data("sticky_kit")) {
        a.data("sticky_kit", !0);
        var w = F;
        var f = a.parent();
        null !=
        x && (f = f.closest(x));
        if (!f.length) throw"failed to find stick parent";
        var v = k = !1;
        (d = null != q ? q && a.closest(q) : g("<div />")) && d.css("position", a.css("position"));
        var A = function () {
          if (!H) {
            E = h.height();
            w = F = J.height();
            var c = parseInt(f.css("border-top-width"), 10);
            var l = parseInt(f.css("padding-top"), 10);
            b = parseInt(f.css("padding-bottom"), 10);
            t = f.offset().top + c + l;
            z = f.height();
            if (k) {
              v = k = !1;
              null == q && (a.insertAfter(d), d.detach());
              a.css({position: "", top: "", width: "", bottom: ""}).removeClass(u);
              var n = !0
            }
            G = a.offset().top -
              (parseInt(a.css("margin-top"), 10) || 0) - p;
            r = a.outerHeight(!0);
            m = a.css("float");
            d && d.css({
              width: M(a),
              height: r,
              display: a.css("display"),
              "vertical-align": a.css("vertical-align"),
              "float": m
            });
            if (n) return e()
          }
        };
        A();
        if (r !== z) {
          var I = void 0;
          var c = p;
          var B = K;
          var e = function () {
            var g;
            if (!H) {
              var l = !1;
              null != B && (--B, 0 >= B && (B = K, A(), l = !0));
              l || F === w || A();
              var n = h.scrollTop();
              null != I && (g = n - I);
              I = n;
              if (k) {
                if (y) {
                  var e = n + r + c > z + t;
                  v && !e && (v = !1, a.css({position: "fixed", bottom: "", top: c}).trigger("sticky_kit:unbottom"))
                }
                n < G && (k = !1,
                  c = p, null == q && ("left" !== m && "right" !== m || a.insertAfter(d), d.detach()), l = {
                  position: "",
                  width: "",
                  top: ""
                }, a.css(l).removeClass(u).trigger("sticky_kit:unstick"));
                D && r + p > E && !v && (c -= g, c = Math.max(E - r, c), c = Math.min(p, c), k && a.css({top: c + "px"}))
              } else n > G && (k = !0, l = {
                position: "fixed",
                top: c
              }, l.width = "border-box" === a.css("box-sizing") ? a.outerWidth() + "px" : a.width() + "px", a.css(l).addClass(u), null == q && (a.after(d), "left" !== m && "right" !== m || d.append(a)), a.trigger("sticky_kit:stick"));
              if (k && y && (null == e && (e = n + r + c > z + t), !v &&
              e)) return v = !0, "static" === f.css("position") && f.css({position: "relative"}), a.css({
                position: "absolute",
                bottom: b,
                top: "auto"
              }).trigger("sticky_kit:bottom")
            }
          };
          var C = function () {
            A();
            return e()
          };
          var L = function () {
            H = !0;
            h.off("touchmove", e);
            h.off("scroll", e);
            h.off("resize", C);
            g(document.body).off("sticky_kit:recalc", C);
            a.off("sticky_kit:detach", L);
            a.removeData("sticky_kit");
            a.css({position: "", bottom: "", top: "", width: ""});
            f.position("position", "");
            if (k) return null == q && ("left" !== m && "right" !== m || a.insertAfter(d),
              d.remove()), a.removeClass(u)
          };
          h.on("touchmove", e);
          h.on("scroll", e);
          h.on("resize", C);
          g(document.body).on("sticky_kit:recalc", C);
          a.on("sticky_kit:detach", L);
          return setTimeout(e, 0)
        }
      }
    };
    var t = 0;
    for (w = this.length; t < w; t++) b = this[t], N(g(b));
    return this
  }
}).call(this);
