import $ from 'jquery';


export default class SlickSlider {
  init() {
    // Initialisation des variables communes aux slides
    var prevArrowTxt = "<button type=\"button\" class=\"btn btn-link slick-prev px-0 text-decoration-none slick-arrow\" aria-label=\"" + ukoo_txt_prev + "\">"+ ukoo_icon_left +"</button>";
    var nextArrowTxt = "<button type=\"button\" class=\"btn btn-link slick-next px-0 text-decoration-none slick-arrow\" aria-label=\"" + ukoo_txt_next + "\">"+ ukoo_icon_right +"</button>";

    $('[data-slick]').not('.slick-initialized').each(function () {
      let self = $(this);
      if (self.data('count') === 1) {
        return;
      }
      self.slick({
        centerMode: false, // Enables centered view with partial prev/next slides. Use with odd numbered slidesToShow counts.
        mobileFirst: false, // Responsive settings use mobile first calculation
        slidesToScroll: 1,
        slidesToShow: 1,
        dots: false,
        arrows:true,
        prevArrow: prevArrowTxt,
        nextArrow: nextArrowTxt,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              dots: true,
              arrows:false
            }
          }
        ]
      });
    });
    // [ukoo_jordan] [2019-10-22 13:40] -> Configuration globale aux produits
    $('.slick__products').not('.slick-initialized').each(function() {
      let self = $(this);
      if(self.data('count') === 1){
        return;
      }
      self.slick({
        respondTo: 'window', // Width that responsive object responds to. Can be 'window', 'slider' or 'min' (the smaller of the two)
        centerMode: false, // Enables centered view with partial prev/next slides. Use with odd numbered slidesToShow counts.
        centerPadding: '40px', // Side padding when in center mode (px or %)
        prevArrow:prevArrowTxt,
        nextArrow:nextArrowTxt,
        infinite: false, // Responsive settings use mobile first calculation
        mobileFirst: false, //Responsive settings use mobile first calculation
        responsive: [
          {
            breakpoint: 9999,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 5,
              slidesToScroll: 5
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3
            }
          },
          {
            breakpoint:575,
            settings: "unslick",
          }
        ]
      });
    });
    // [ukoo_jordan] [2021-12-17 10:34] ->Configuration globale aux produits
    $('.slick__products-small').not('.slick-initialized').each(function() {
      let self = $(this);
      if(self.data('count') === 1){
        return;
      }
      self.slick({
        respondTo: 'window', // Width that responsive object responds to. Can be 'window', 'slider' or 'min' (the smaller of the two)
        centerMode: false, // Enables centered view with partial prev/next slides. Use with odd numbered slidesToShow counts.
        centerPadding: '40px', // Side padding when in center mode (px or %)
        prevArrow:prevArrowTxt,
        nextArrow:nextArrowTxt,
        infinite: false, // Responsive settings use mobile first calculation
        mobileFirst: false, //Responsive settings use mobile first calculation
        responsive: [
          {
            breakpoint: 9999,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint:575,
            settings: "unslick",
          }
        ]
      });
    });
    // [ukoo_jordan] [2019-10-22 13:40] -> Configuration globale aux logos
    $('.slick__logos').not('.slick-initialized').each(function() {
      let self = $(this);
      if(self.data('count') === 1){
        return;
      }
      self.slick({
        respondTo: 'min', // Width that responsive object responds to. Can be 'window', 'slider' or 'min' (the smaller of the two)
        prevArrow:prevArrowTxt,
        nextArrow:nextArrowTxt,
        infinite: false, // Responsive settings use mobile first calculation
        mobileFirst: true, //Responsive settings use mobile first calculation
        rows:2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 2200,
            settings: {
              slidesToShow: 9,
            }
          },
          {
            breakpoint: 1900,
            settings: {
              slidesToShow: 8,
            }
          },
          {
            breakpoint: 1600,
            settings: {
              slidesToShow: 8,
            }
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 6,
            }
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 5,
            }
          },
          {
            breakpoint: 550,
            settings: {
              slidesToShow: 4,
            }
          },
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
            }
          }
        ]
      });
    });
  }
  refresh() {
    $('.slick-slider').slick('refresh');
  }
}
