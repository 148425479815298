import $ from 'jquery';

export default function readmore() {
    // Readmore settings
    var readmore_btn_open = '<a class="btn-readmore" href="#">' + ukoo_icon_plus +
      ukoo_txt_readmore_open + '</a>';
    var readmore_btn_close = '<a class="btn-readmore" href="#">' + ukoo_icon_minus +
      ukoo_txt_readmore_close + '</a>';
    var readmore_speed = 200;

    // Readmore functions
    $('.js-readmore').readmore({
      speed: readmore_speed,
      // Valeur par défaut si aucun "max-height" n'est précisé sur l'élément, sinon le script se basera sur ce "max-height" (avantage : peut être différents en fonction des résol si utilisation de media queries)
      collapsedHeight: 65,
      //
      moreLink: readmore_btn_open,
      lessLink: readmore_btn_close,
      embedCSS: false,
    });

    $('.js-readmore--big').readmore({
      speed: readmore_speed,
      // Valeur par défaut si aucun "max-height" n'est précisé sur l'élément, sinon le script se basera sur ce "max-height" (avantage : peut être différents en fonction des résol si utilisation de media queries)
      collapsedHeight: 120,
      //
      moreLink: readmore_btn_open,
      lessLink: readmore_btn_close,
      embedCSS: false,
    });
}
